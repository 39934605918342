.route.event-listing-route {
  .event-listing {
    @include smallAndMedium {
      display: block;
    }
  }

  .time-filters {
    background-color: white;
    margin-bottom: 24px;
    padding: 14px 16px;
    font-weight: 600;
    font-size: 16px;
    border: $divider;

    @include mediumAndLarge {
      font-size: 20px;
      border: none;
      padding: 16px 20px;
    }

    label {
      display: inline-block;
    }

    .select-filter {
      border: none;
      padding: 0;
      color: $blue;
      background-position: right 2px top 40%;
      padding-right: 18px;
      line-height: 1;
      font-weight: 600;
      font-size: 16px;

      @include mediumAndLarge {
        font-size: 20px;
      }

      option {
        color: $dark-gray;
      }

      &:focus:not(:focus-visible) {
        outline: none;
      }
    }

    .select-filter,
    .filter-single-value {
      margin: 0 5px;

      @include mediumAndLarge {
        margin: 0 10px;
      }
    }
  }

  .no-results {
    @extend %msi-card;
  }

  article.event-listing-item {
    padding: 0;
    background: $light-blue;
    border: 1px solid $medium-light-blue;

    @include desktopExperience {
      padding-right: 24px;

      > *:not(figure) {
        margin-left: 24px;
        margin-right: 24px;
      }
    }

    @include mobileExperience {
      > *:not(figure) {
        margin-left: 16px;
        margin-right: 16px;
      }
    }

    h2 {
      padding-top: 24px;
      margin-bottom: 16px;

      @include small {
        padding-top: 20px;
      }
    }

    .subtitle {
      color: $blue;
      font-weight: bold;
    }

    figure {
      padding-right: 0;
      flex-shrink: 0;
      margin: -1px; // to hide the border
      margin-right: 0;
      border-right: 1px solid $medium-light-blue;

      @include small {
        border-right: 0;
        border-bottom: 1px solid $medium-light-blue;
        margin-right: -1px;
        bottom-bottom: 0;
      }

      img {
        @include mediumOnly {
          max-width: 250px;
          height: calc(100% + 2px);
        }

        // TODO Use $large-threshold now that it has increased?
        @media (min-width: $small-threshold-min) and (max-width: 510px) {
          max-width: 170px;
        }
      }
    }

    div.button.primary {
      margin-bottom: 24px;
    }

    @include mediumAndLarge {
      figure {
        grid-row: 1/10;
      }
    }

    @include mediumOnly {
      width: 100%;

      figure {
        grid-column: 1;
      }

      .title-link {
        grid-column: 2;
        grid-row: 1;
      }

      .subtitle {
        grid-column: 2;
        grid-row: 2;
      }

      .description {
        grid-column: 2;
        grid-row: 3;
      }

      .button.primary {
        grid-column: 2;
        grid-row: 4;
      }
    }
  }
}
