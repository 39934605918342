@include large {
  .has-sidebar > .main {
    width: calc((2 / 3) * 100% - 8px);
  }
}

.site-content {
  .checkout-route {
    .mobile-checkout-heading {
      background-color: $light-blue;
      color: $dark-blue;
      text-shadow: none;
      border-bottom: $divider;
    }
  }
}

.checkout-route {
  .form.checkout {
    .members-banner {
      max-width: unset;
    }

    .form-input-group label.form-input:not(.checkbox):not(.radio-item):last-of-type {
      margin-bottom: 0;
    }
  }

  .purchaser-details {
    h2 {
      line-height: 24px; //same as font-height
    }
  }

  .upsell {
    h2 {
      margin-bottom: 0;
    }

    &.radio-group {
      .radio-items {
        flex-wrap: wrap;

        .radio-item.selectable {
          padding: 15px 22px;
          margin-bottom: 8px;
        }

        @include mediumAndLarge {
          flex-wrap: nowrap;

          .radio-item.selectable {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .payment {
    > * {
      max-width: 472px;
    }

    h2 {
      margin-top: 0;
      line-height: 24px; //same as font-height
    }
  }

  .total-outstanding,
  .purchaser-details,
  .upsell,
  .payment,
  .information,
  .apply-promo-codes-checkout {
    @extend %msi-card;
    margin-bottom: $card-spacing;
  }

  button.add-card-button,
  button.add-promo-button {
    font-weight: bold;

    &:after {
      margin: 0;
      width: 23px;
      height: 23px;
      border: 2px solid $blue;
      border-radius: 100%;
      float: right;
      margin-left: 8px;
    }
  }

  button.apply-card,
  button.remove {
    background-color: transparent;
    color: $dark-blue;
    text-shadow: none;
    padding: 20px 0;
    margin-left: 18px;

    &:hover {
      color: $blue;
      background-color: transparent;
    }
  }

  button.apply-card {
    margin-top: 34px;
  }

  .applied-cards .applied-card .code-wrapper {
    background-color: white;
    background-image: icon('tick', #8cc344);
    background-repeat: no-repeat;
    background-position: 90% center;
  }

  .total-outstanding {
    h3 {
      margin-top: 0;
    }

    p {
      margin-bottom: 0;
    }
  }
}
