.modal.login .modal-window {
  @extend %msi-card;
  padding: 0;
  border: 0;

  h2 {
    text-align: center;
  }

  button[type='submit'] {
    margin-top: 24px;
    width: 100%;
  }
}
