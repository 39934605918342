.radio-group .radio-item.selectable,
.upsell label.radio-item.form-input.selectable.ticket-type {
  width: auto;
  height: auto;
  border: none;
}

.upsell label.radio-item.form-input.selectable.ticket-type {
  flex-basis: calc(20% - 8px);

  &:last-child {
    margin-right: 0;
  }
}

.radio-group .radio-item {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.radio-group .radio-item.selectable {
  .label small {
    font-size: inherit;
  }
}

div.message {
  &.info,
  &.warning,
  &.success,
  &.error,
  &.members-banner.logged-out {
    border: none;
    border-left: 5px solid;
    color: black;
    padding: 18px 22px;

    svg {
      background-color: transparent;
      line-height: 23px;
      margin-right: 14px;
      border: 2px solid;
    }

    a {
      color: black;
      text-decoration: underline;
    }

    @include mediumAndLarge {
      align-items: center;
    }
  }

  &.info,
  &.members-banner.logged-out {
    background-color: #e1f0fa;
    border-color: #008deb;

    svg {
      border-color: #008deb;

      circle, path {
        stroke: #008deb;
      }
    }
  }

  &.warning {
    background-color: #faf0e7;
    border-color: #fb8727;

    svg {
      border-color: #fb8727;
      stroke: #fb8727;
    }
  }

  &.error {
    background-color: #fbe8eb;
    border-color: #ff3653;

    svg {
      border-color: #ff3653;
      stroke: #ff3653;
    }
  }

  &.success {
    background-color: #ecf5e5;
    border-color: #6ebf14;

    svg {
      border-color: #6ebf14;
      stroke: #6ebf14;
    }
  }

  &.expires-in {
    svg {
      border: none;
      background: none;
    }
  }
}

article.event-listing-item,
.content-detail-page .description-wrapper {
  .subtitle {
    margin-bottom: 8px;

    .label,
    .value {
      display: inline-block;
    }

    .label {
      font-weight: 700;
      margin-right: 4px;
    }
  }
}

label.form-input.checkbox {
  align-items: center;
}

input[type='checkbox'] {
  border-color: $primary-color;
  background-color: white;
  width: 32px;
  height: 32px;

  &:checked:before {
    left: 11px;
    top: 5px;
    width: 8px;
    height: 15px;
    filter: drop-shadow($shadow);
  }
}

.section-header {
  background-color: $light-blue;
  padding: 0;

  h3 {
    @extend %h2;
  }

  @include smallAndMedium {
    margin-left: 0;
    margin-right: 0;
  }
}

.select-date-wrapper {
  .section-header {
    display: none;
  }
}

.select-session-wrapper {
  .section-header {
    p {
      display: none;
    }
  }
}

.date-first,
.reserve-single-event-form {
  @include desktopExperience {
    .date-or-flexible-tickets,
    .select-session-inner,
    .tickets {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.session-detail-route {
  .num-tickets {
    background-color: $blue;
    color: white;
  }
}
