//footer height
$footer-height: 80px;
$pre-footer-height: 176px;

footer {
  color: $white;
  font-size: 14px;
  line-height: 17px;
  background: linear-gradient(90deg, #143a75 0, #0a1f54 100%), $dark-blue;
  background-size: 100% $pre-footer-height;
  background-repeat: no-repeat;

  .site-footer-inner {
    padding: 0;
  }

  .pre-footer-area {
    height: $pre-footer-height;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 32px;

    span {
      font-weight: 600;
      @include responsiveProperty('font-size', 22px, 26px, 26px);
      @include responsiveProperty('line-height', 24px, 32px, 32px);
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.4);
      margin-bottom: 8px;
    }

    a {
      @include responsiveProperty('font-size', 16px, 20px, 20px);
      @include responsiveProperty('line-height', 24px, 32px, 32px);
      color: $primary-color;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.4);

      svg {
        transform: rotate(180deg);
        margin-left: 8px;
      }
    }
  }

  .footer-area {
    padding: 20px 12px;
    text-align: center;

    @include mediumAndLarge {
      padding: 20px 32px;
      text-align: left;
    }

    .copyright {
      opacity: 0.5;
    }

    ul {
      list-style: none;
      display: block;
      margin: 0 auto 7px auto;
      padding: 0;

      &:last-child {
        margin: 0;
      }

      li {
        display: inline-block;

        a,
        a:not(.button) {
          color: white;
          text-decoration: none;

          &:hover {
            color: white;
            text-decoration: underline;
          }
        }
      }
    }

    p {
      margin: 0;
    }
  }
}

.mobile-footer .main-button-wrapper button {
  line-height: 13px;
  padding-top: 0;
  padding-bottom: 0;
}
