.route.giftcards {
  .ticket-groups {
    .ticket-group {
      @extend %msi-card;
      margin-bottom: $card-spacing;
    }
  }

  .ticket-types {
    .ticket-type {
      margin-bottom: 24px;

      .radio-item {
        margin-bottom: 0;
      }
    }
  }

  .custom-value {
    margin-top: 12px;

    .currency-symbol {
      font-weight: 700;
      color: $dark-blue;
      padding-right: 6px;
    }

    input {
      width: 155px;
    }
  }
}
