.stepper {
  padding: 7px;
  border: 1px solid rgba(42, 169, 224, 0.5);
  background-color: white;
  border-radius: 5px;

  button {
    flex-grow: 1;
    padding: 13px;

    svg {
      filter: drop-shadow($shadow);
    }
  }

  .count {
    width: 48px;
    line-height: 44px;
  }
}

.site-content .content-detail-page {
  .mobile-content-wrapper {
    h1 {
      color: $dark-blue;
      text-align: left;
    }

    .subtitle {
      border-bottom: $divider;
      color: $blue;
      padding-bottom: 16px;
      margin-top: -8px;
      margin-bottom: 28px;
    }

    .description-link {
      margin-top: -8px;
    }
  }

  .date-first .inline-mode {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;

    @include mediumOnly {
      display: block;
    }

    @include large {
      flex-wrap: nowrap;

      .today-tomorrow-buttons {
        padding-right: 40px;
      }
    }
  }

  figure {
    margin-bottom: 0;
  }

  .main .actions {
    margin-bottom: 0;
  }

  .survey,
  .selection .date-or-flexible-tickets,
  .selection .select-session-wrapper,
  .selection .select-tickets,
  .membership-level,
  .membership-roles:not(:empty),
  .auto-renew,
  .recipient-contact-information,
  .previous-membership,
  article.event .description-wrapper,
  article.event > h1,
  .members-only,
  .gom-gift-code-wrapper {
    @extend %msi-card;
    padding: 22px 16px 16px;
    margin-bottom: 24px;

    @include large {
      padding: 32px 40px 40px;
      margin-bottom: $card-spacing;
    }
  }

  &.upsell {
    article.event .description-wrapper {
      padding-top: 0;
    }
  }

  .members-only {
    h2 {
      margin-bottom: 12px;
    }

    h3 {
      margin-top: 0;
    }

    .signup-or-login {
      .signup-cta {
        margin-top: 24px;
        padding-top: 24px;
        border-top: $divider;
      }

      a.button.login {
        margin-bottom: 0;

        @include small {
          width: 100%;
        }
      }

      @include large {
        display: flex;
        align-items: center;

        .signup-cta {
          border-left: 1px solid $medium-light-blue;
          margin-left: 24px;
          margin-top: 0;
          padding: 10px 0 10px 24px;
          border-top: none;
        }

        a.button.login {
          width: auto;
        }
      }
    }
  }

  article.event {
    > h1 {
      @extend %h2;
      margin-bottom: 0;
      border-bottom: 0;
      padding-bottom: 8px;
    }

    .description-wrapper {
      margin-top: 0;
      border-top: 0;
    }

    .subtitle {
      border-bottom: $divider;
      padding-bottom: 18px;
      margin-bottom: 32px;
      color: $primary-color;
      font-weight: 700;
    }

    .description {
      p {
        margin: 0;
      }
    }
  }

  article.mobile-event {
    .subtitle {
      text-align: left;
    }
  }

  .selection {
    margin-top: 0;
    padding-top: 0;

    .select-date-wrapper > h3 {
      display: none;
    }

    > h2 {
      @extend %msi-card;
      margin-bottom: 0;
      border-bottom: none;
      padding-bottom: 0;
    }

    .date-or-flexible-tickets {
      padding-top: 1px;
      border-top: none;
      display: block;
    }

    .select-tickets {
      position: relative;

      .ticket-type-headers {
        margin: 0 -16px 32px;

        .ticket-type-header,
        .price-header,
        .quantity-header {
          border-bottom: $divider;
          font-size: 14px;
          padding: 8px 0;
          margin: 0 16px;
        }
      }

      .ticket-type-headers .quantity-header,
      .ticket-type > .stepper {
        flex-basis: 152px;
        margin-left: 16px;
        flex-shrink: 0;
      }

      @include large {
        $price-column-width: 154px;

        .ticket-type-headers .price-header {
          flex-basis: $price-column-width;
        }

        .ticket-type .price-container {
          flex-basis: $price-column-width + 16px;
        }
      }

      .ticket-type.fixed-price .price-wrapper {
        font-weight: normal;
        font-size: 16px;

        &.discount {
          color: $lime;
          font-weight: bold;
          font-size: 20px;
        }

        .fractional-part {
          font-size: 11px;
        }
      }

      .guest-ticket-steppers,
      .member-ticket-steppers {
        > h3 {
          @extend %h3;
        }
      }

      > div:not(:last-child):not(.promo-code-banner) {
        border-bottom: $divider;
        margin-bottom: 32px;
        padding-bottom: 32px;
      }
    }

    .ticket-type-description {
      .title {
        font-size: 18px;

        @include large {
          font-size: 22px;
        }
      }

      @include small {
        .subtitle {
          margin-top: 4px;
          margin-bottom: 2px;
        }
      }
    }
  }

  .navigate-back {
    display: none;
  }

  .reserve-single-event > h2 {
    display: none;
  }

  .auto-renew {
    h3 {
      margin-top: 0;
      margin-bottom: 8px;
    }

    p {
      margin-top: 0;
    }
  }

  .membership-level {
    h3.label {
      @extend h2;
    }
  }

  .previous-membership {
    .label {
      @extend h3;
    }
  }

  .radio-item,
  .form-input {
    .label {
      @extend h4;
      margin: 0;
    }
  }

  .membership-roles {
    .member-names,
    .unnamed-members {
      margin-bottom: 26px;
    }

    .name-fields,
    .member-names,
    .unnamed-members {
      &:last-child {
        margin-bottom: 0;
      }
    }

    .member-names {
      > .label {
        display: none;
      }
    }
  }

  .radio-group {
    > h3.label {
      margin-bottom: 12px;
    }

    > .section-header h2 {
      @include large() {
        margin-bottom: 12px;
      }
    }

    .radio-items h3.label {
      margin-bottom: 24px;
    }

    span.label {
      margin: 0;
      margin-top: 3px;
    }
  }

  @include small {
    .actions {
      flex-direction: column;

      .navigate-back {
        margin-top: 28px;
      }
    }
  }

  .buy-membership {
    .footnote {
      border: 1px solid #bae2f5;
      color: $dark-blue;
      font-weight: 600;
      padding: 20px;
      display: flex;

      &:before {
        content: '';
        width: 26px;
        height: 26px;
        flex-shrink: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z' stroke='%230A1F54' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.9999 17.8V13' stroke='%230A1F54' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.9999 8.19922H13.0103' stroke='%230A1F54' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        display: block;
        margin-right: 16px;
      }
    }
  }

  &.upsell .selection > h2 {
    display: none;
  }

  .citypass-coupons {
    label.checkbox {
      .label {
        font-weight: normal;
        color: $dark-gray;
      }
    }

    .ticket-groups {
      border-top: $divider;
      padding-top: 32px;
      margin-top: 32px;

      @include large {
        > h2 {
          padding-right: 114px;

          &:after {
            background-image: url(images/city-pass-logo.png);
            content: '';
            display: inline-block;
            background-repeat: no-repeat;
            width: 114px;
            background-size: 114px;
            height: 28px;
            position: absolute;
            right: -3px;
          }
        }
      }
    }

    .coupon {
      margin-bottom: 32px;
    }

    .coupons:last-child .coupon:last-child {
      margin-bottom: 0;
    }
  }

  .conflicting {
    background-repeat: no-repeat;
    padding-left: 32px;
    background-position: left top;
    line-height: 26px;
  }

  .conflicting {
    background-image: icon('alertCircle', $blue);
  }

  .select-session-wrapper > p {
    &:first-of-type {
      margin-top: 32px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.content-detail-page.membership .select-tickets .section-header {
  margin-top: 16px;
}

.select-session-wrapper .select-available-session {
  h3 {
    @extend %h2;
  }
}

.selected-date-time,
.selectable-button {
  background-color: white;
  border-radius: 5px;
}

.date-selector,
.select-available-session {
  .selectable-button {
    border-radius: 4px;
    border: none;

    &:not(.sold-out) {
      background-color: $blue;
      color: white;

      @include hover {
        background-color: $dark-blue;
      }
    }

    .availability-status {
      color: rgb(255 255 255 / 75%);
    }

    &.sold-out {
      background-color: $dark-blue;

      .session-time {
        color: white;
      }
    }
  }

  &.has-prices {
    .selectable-button {
      flex-basis: calc(50% - 6px);

      @include desktopExperience {
        flex-basis: calc(25% - 9px);
      }
    }
  }
}

.selected-date-time {
  outline: 1px solid rgba(42, 169, 224, 0.5);
}

.date-picker button.more-dates {
  color: $dark-blue;
  background-color: rgb(58 147 184 / 12%);

  &:hover {
    color: $dark-blue;
    background-color: rgb(58 147 184 / 18%);
  }
}
