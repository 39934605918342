//header background heights
$header-bg-height-small: 408px;
$header-bg-height-medium: 448px;
$header-bg-height-large: 448px;

body {
  @include responsiveProperty(
    'background-size',
    auto $header-bg-height-small,
    auto $header-bg-height-medium,
    auto $header-bg-height-large
  );
  background-image: url(./images/header-background.jpg), linear-gradient(90deg, #102d64 0, #091f54 100%);
  background-repeat: no-repeat;
  background-position: top center;
}

header.site-header {
  padding: 26px 0 0 0;
  margin-bottom: 24px;

  @include large {
    margin-bottom: 16px;
  }

  .site-header-inner {
    align-items: flex-start;
  }

  .logo {
    width: 165px;
  }

  .account-links {
    text-align: right;
    display: flex;
    align-items: flex-end;

    > a {
      padding-top: 0;
      padding-bottom: 0;
    }

    @include smallAndMedium {
      flex-direction: column;
    }
  }

  details.member-menu {
    height: 32px;

    summary.member-menu-icon {
      svg {
        stroke: white;
      }
    }

    ul.member-menu-items {
      top: 96px;
      background-color: #f0f0f0;

      @include mediumAndLarge {
        top: 54px;
      }
    }

    .greeting-title {
      font-size: 12px;
      line-height: 24px;
      color: $black;
    }

    .greeting-username {
      font-family: $secondary-font;
      font-size: 22px;
      line-height: 24px;
    }

    button {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }

    &[open] {
      summary.member-menu-icon:after {
        svg {
          stroke: white;
        }
      }
    }
  }
}
