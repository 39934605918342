.date-picker > h3 {
  display: none;
}

.date-first {
  .today-tomorrow-buttons {
    button.selectable {
      border: none;
      font-weight: 500;
    }
  }

  .datepicker-inline-container {
    margin-top: 32px;
  }

  @include large {
    .today-tomorrow-buttons {
      border-right: $divider;
      padding-right: 40px;
      padding-top: 62px;
    }

    .datepicker-inline-container {
      padding-left: 40px;
      margin-top: 0;
      max-width: 73%;
    }
  }
}
