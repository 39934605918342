@import "@/styles/globals.scss";
@import '../../styles/defaults/css-properties';

// Variables
$light-blue: #eaf6fc;
$medium-light-blue: #8acfee;
$blue: #2aa9e0;
$medium-dark-blue: #143a75;
$dark-blue: #0a1f54;
$lime: #85c045;

$black: #525252;
$dark-gray: #3b3b3b;
$medium-gray: #ccc;
$light-gray: #bcbcbc;
$lighter-gray: #e8e8e8;
$very-light-gray: #f1f1f1;

$divider: 1px solid #8acfee;
$shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

$body-color: $black;

$cart-divider-color: rgba(255, 255, 255, 0.2);
$cart-item-removal-overlay-color: rgba($dark-blue, 0.96);
$cart-remove-button-background-color: rgba(0, 0, 0, 0.3);

$radio-button-background-color: white;
$radio-button-checked-background-color: white;
$radio-button-border-color: $light-gray;
$radio-button-checked-background-color: $primary-color;
$radio-button-selected-color: white;

$selectable-height: 50px;

// distance between cards
$card-spacing: 40px;

$success-background-color: $lime;

$identity-form-input-width: 472px;

// Defaults
@import '../../styles/defaults/index';
@import '../../styles/helpers/index';

// Placeholders
%h1,
%h2,
%h3,
%h4,
%h5,
%h6 {
  font-family: $secondary-font;
  color: $dark-blue;
}

%h1 {
  font-family: $primary-font;
  font-size: 34px;
  line-height: 41px;
  font-weight: 600;

  @include small {
    font-size: 28px;
    line-height: 32px;
  }
}

%h2 {
  color: $dark-blue;
  font-family: $primary-font;
  font-size: 22px;
  line-height: 26px;
  margin-top: 0;
  margin-bottom: 20px;
  text-transform: none;
  font-weight: 600;

  @include mediumAndLarge {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: $spacing-m;
  }
}

%h3 {
  font-family: $primary-font;
  font-size: 22px;
  line-height: 24px;
  font-weight: 600;
}

%h4 {
  font-family: $primary-font;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

%p {
  color: $black;
  line-height: 24px;
}

%button {
  font-weight: 600;
  border-radius: 4px;
  font-size: 18px;
  text-shadow: $shadow;
  cursor: pointer;
}

%primary-button {
  padding: 20px 40px;
  text-transform: none;
}

%secondary-button {
  background-color: $blue;
  padding: 15px 28px;
  color: $white;
  line-height: 1;

  .label {
    font-weight: bold;
  }
}

%primary-button-hover,
%secondary-button-hover,
%secondary-button-active {
  background-color: $dark-blue;
  color: white;
}

%back-button {
  text-transform: none;
  font-size: 15px;
  font-weight: 700;

  @include small {
    width: 100%;
    text-align: center;
  }
}

%msi-card {
  background: $light-blue;
  padding: $spacing-s;
  border: 1px solid $medium-light-blue;

  @include mediumAndLarge() {
    padding: 40px;
  }
}

%actions {
  border-top: none;
  padding-top: 0;
}

%label {
  font-weight: 700;
  color: $dark-blue;
  margin-bottom: 3px;
}

%input,
select {
  padding: 19px 16px 16px 16px;
  border-radius: 5px;
}

@include fullHeightTeaserImages;

// Base elements
@import 'page';
@import 'elements';
@import 'links-and-buttons';
@import 'form-inputs';
@import 'modals';
@import 'messages';
@import 'badges';

// Components
@import '_header';
@import '_footer';
@import 'filters';
@import 'actions';
@import 'cart';
@import 'selection';
@import 'steppers-1';
@import 'calendar';
@import 'identity-form';
@import 'members-menu';
@import 'legend';

// Routes
@import 'checkout';
@import 'completed';
@import 'event-list';
@import 'buy-membership';
@import 'gift-of-membership';
@import 'forgot-membership';
@import 'event';
@import 'resend-tickets';
@import 'manage-order';
@import 'home';
@import 'my-account';

// Theme
@import 'sass/_icons';
@import 'sass/_calendar';
@import 'sass/_cart';
@import 'sass/_elements';
@import 'sass/_footer';
@import 'sass/_header';
@import 'sass/_modals';
@import 'sass/_page';
@import 'sass/checkout';
@import 'sass/error';
@import 'sass/event-listing';
@import 'sass/event';
@import 'sass/gift-card';
@import 'sass/membership';
