.site-content {
  .route {
    > h1 {
      color: $white;
      text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.4);
      text-align: center;
      max-width: 750px;
      margin: 0 auto 24px;

      @include small {
        padding: 0 16px;
      }

      @include large {
        margin-bottom: 52px;
      }

      &:after {
        display: block;
        font-size: 20px;
        font-weight: normal;
        text-transform: none;
        line-height: 32px;
      }
    }

    .mobile-content-wrapper h1 {
      color: #ffffff;
      text-align: center;
    }

    .mobile-checkout-heading {
      padding: 20px 70px;
    }
  }
}

.body-inner {
  &.route-event,
  &.route-event-action,
  &.route-checkout {
    .site-content {
      @include mobileExperience {
        background-color: $white;
      }
    }
  }
}

//action separators
.checkout-route .main > .actions,
.content-detail-page .main .actions {
  margin-top: 26px;
  margin-bottom: 26px;

  @include large {
    margin-top: $card-spacing;
    margin-bottom: 92px;
  }
}

.main > .loader,
.content-detail-page.loading > .loader,
body.home .site-content-inner > .loader {
  @extend %msi-card;
}

.loader {
  box-sizing: content-box;
}

.content-detail-page.loading > .loader,
body.home .site-content-inner > .loader {
  margin-bottom: 32px;
}

.reset-password-route {
  > p, > form {
    @extend %msi-card;
  }

  .password-requirements:not(.error):not(.success) {
    border-color: $medium-light-blue;
    background-color: white;
  }

  > form {
    margin-bottom: 16px;
  }
}

.home-route {
  margin-bottom: 40px;

  .options {
    @extend %msi-card;

    @include large {
      > section:not(.first) {
        border-color: $medium-light-blue;
      }
    }

    @include smallAndMedium {
      .block {
        margin-bottom: 16px;
      }

      > section.third {
        padding-bottom: 0;
        margin-bottom: 8px;
        border-bottom: none;
      }

      > section:not(.third) {
        border-color: $medium-light-blue;
      }
    }
  }
}

//Typography
body {
  color: $black;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}

h1 {
  > .subtitle {
    color: rgba(white, 0.8);
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: normal;
    display: block;
    margin-top: 8px;

    @include large {
      text-shadow: $shadow;
      font-size: 20px;
      line-height: 32px;
    }
  }
}

.email-submit-form {
  .message.invalid {
    margin: 0;
  }
  .form-input.email.invalid {
    margin-bottom: 8px;
  }
}

.my-account-wrapper {
  @extend %msi-card;
}
