section.cart {
  background: linear-gradient(90deg, $medium-dark-blue 0%, $dark-blue 100%);
  color: $white;

  .total-prices {
    background-color: $dark-blue;
  }

  > h2 {
    border-bottom: 1px solid $cart-divider-color;
    padding: 16px 16px 15px;
  }

  .price-wrapper {
    .digits {
      display: inline-flex;
      align-items: flex-start;
      line-height: 1;
    }
  }

  h2,
  h3,
  h4,
  p,
  .content .ticket header .price-wrapper,
  .content .ticket-date,
  .content .total-due .price-wrapper,
  .content .discounts h3 {
    color: white;
  }

  .content {
    .tickets {
      padding: 0;
    }

    .ticket-type {
      margin-bottom: 0;
    }

    .visit-date {
      margin-top: 18px;
    }

    .visit-date,
    .ticket-wrapper {
      padding: 0 $spacing-m;
    }

    .ticket-wrapper {
      margin-bottom: 0;
      padding: 16px;

      .remove-cart-item-overlay button,
      .remove-cart-item-overlay button.primary {
        background-color: $secondary-color;
        color: white;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }

    .ticket header {
      margin-bottom: 8px;

      h4 {
        font-size: 18px;
      }

      .price-wrapper {
        font-weight: bold;
      }
    }

    .apply-promo-codes .joint-input-button > .wrapper {
      .promo-number-field {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 10px 16px;
      }

      button.apply-promo {
        padding: 9px 22px;
      }
    }

    .ticket-date {
      font-size: 14px;
    }

    .total-prices {
      padding: 16px 16px 20px;

      .line-item {
        margin-bottom: 8px;
      }

      ul.discounts-content li {
        line-height: 16px;
      }
    }

    .discounts {
      h3 {
        font-size: 16px;
      }
    }


    .total-prices .line-item,
    .total-prices .line-item:not(.total-due) {
      h3,
      .price-wrapper {
        line-height: 1;
        color: white;
      }
    }

    .total-due {
      padding-top: 16px;

      h3,
      .price-wrapper {
        font-size: 18px;
      }
    }

    .empty-cart-message {
      padding: 16px 16px 24px;
    }
  }

  .price-wrapper .fractional-part {
    font-size: 12px;
  }

  form.actions {
    padding: 16px;
  }
}

.cart .content .ticket-wrapper .remove-cart-item-overlay button.cancel-remove,
.mobile-cart .more-events-button {
  background-color: transparent;
  border: 2px solid white;
}
