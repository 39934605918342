.error {
  .site-content-inner section {
    @extend %msi-card;

    > h1 {
      margin-top: 0;
    }

    > ul {
      margin-bottom: 0;
    }
  }
}
