.forgot-membership-route {
  .navigate-back {
    display: block;
    width: 100%;
    margin-bottom: $spacing-l;
    border: 1px solid $medium-light-blue;
    border-top: 0;
    margin-top: 0;
    background-color: $light-blue;
    padding-bottom: 22px;

    @include mediumAndLarge() {
      padding-bottom: 40px;
      padding-left: 40px;
      text-align: left;
    }
  }

  .message.warning + .navigate-back {
    padding-top: 40px;
  }

  .instructions,
  > form {
    @extend %msi-card;
  }

  .instructions {
    padding-bottom: 0;
  }

  > form {
    margin-top: 0;
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 22px;
  }
}

.route.membership {
  label.form-input.text .label {
    margin-bottom: 3px;
  }

  label.physical_card,
  .auto-renew {
    .radio-items {
      display: inline-flex;

      .radio-item {
        margin-right: 16px;
        margin-bottom: 0;

        .label {
          white-space: nowrap;
        }
      }
    }
  }

  .redeem-membership-route,
  .gom-gift-code-wrapper,
  .buy-membership,
  .select-tickets {
    .section-header {
      margin-bottom: 12px;
      margin-top: 0;

      &.membership-details {
        margin: 0;
        background: $light-blue;
        border: 1px solid $medium-light-blue;
        border-bottom: none;

        @include large {
          padding: 32px 40px 0;
        }
      }
    }
  }

  .redeem-membership-form .membership-level .radio-item:first-child input {
    background-color: white;
  }

  form.buy-membership .membership-roles {
    border-top: none;
    padding-top: 12px;

    .name-fields:not(:last-child) {
      margin-top: 16px;
      margin-bottom: 0;
    }
  }

  .survey {
    .label {
      margin-bottom: 12px;
    }
  }
}

form.buy-membership {
  .membership-level .radio-items,
  .auto-renew .radio-items,
  .membership-roles {
    @include desktopExperience {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
